<template>
  <div class="table-grid-body-row"
       :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr);`"
  >
    <div
        class="table-grid-body-row__cell align-center justify-center"
        :style="`grid-column: span ${(numCols )};  min-height: 200px!important;`"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "GridTableEmptyData",
  props: {
    numCols: {
      type: Number,
      default: 0
    },
    text:{
      type: String,
      default: 'Нет данных'
    }
  },
}
</script>

<style scoped>

</style>