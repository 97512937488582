<template>
  <div class="table-grid">
    <div v-if="useSearch" class="d-flex flex-row justify-space-between align-center mb-3">
      <slot name="nameTable">
        <h1 class="section-header__title">
          {{ nameTable }}
        </h1>
      </slot>
      <div class="d-flex flex-row align-start">
        <v-icon
            size="20"
            color="info"
            class="mt-2 mr-2"
        >
          mdi-magnify
        </v-icon>
        <v-text-field
            :value="search"
            color="info"
            outlined
            :label="labelSearch"
            class="search-field"
            dense
            :loading="searching"
            @change="changeSearch($event)"
            style="min-width: 500px"
        />
      </div>
    </div>
    <div
        v-if="isGridTable"
        class="table-grid__container pb-2"
        :style="`grid-template-columns : repeat(${numCols}, 1fr); max-height: ${resizedMaxHeight};`"
    >
      <!--       header -->
      <grid-table-header-row :headers="headers" :num-cols="numCols" :color="loaderColor" :have-vuetify="haveVuetify">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name"/>
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
          <slot :name="name" v-bind="slotData"/>
        </template>
      </grid-table-header-row>
      <!--        body-->
      <template v-if="loadingData || loading">
        <div class="d-flex flex-row align-center" :style="`grid-column: span ${numCols}; min-height: 200px;`">
          <slot name="loading">
            <v-progress-linear v-if="haveVuetify" indeterminate :color="loaderColor"/>
            <template v-else>
              Loading...
            </template>
          </slot>
        </div>
      </template>
      <grid-table-empty-data :num-cols="numCols" v-else-if="showingServerData.length === 0"/>
      <grid-table-empty-data :num-cols="numCols" v-else-if="error" :text="errorText"/>
      <template v-else>
        <grid-table-body-row
            v-for="(item, j) in showingServerData"
            :row="item"
            :key="(item[keyName] != null ? item[keyName]  : j)"
            :num-cols="numCols"
            :index="j"
            :paint-background="paintBackground"
            :headers="headersForRows"
            @row-action="rowAction($event,item)"
        >
          <slot v-for="(_, name) in $slots" :name="name" :slot="name"/>
          <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
            <slot v-if="useSlots" :name="name" v-bind="slotData"/>
          </template>
        </grid-table-body-row>
      </template>
    </div>
    <table v-else
           class="table-on-td"
           :style="`max-height: ${resizedMaxHeight};`"
    >
      <thead>
      <tr>
        <th
            v-for="(item, i) in headers" :key="i"
            :class="item.headerClass"
            :colspan="item.cols"
            :rowspan="item.rows"
            :ref="'table-header__cell-'+item.dataField"
            :style="`${item.headerStyles}`"
        >
          <slot :name="'header-'+item.dataField" :item="item">
            <span v-html="item.text"></span>
            <span
                class="d-flex align-center justify-center"
                v-if="item.sortable"
            >
                <template v-if="sort.header === item.dataField">

                </template>
                <template v-else>

                </template>
          </span>
          </slot>
        </th>
      </tr>
      </thead>
      <tbody>
      <template v-if="loadingData || loading">
        <tr>
          <td class="d-flex flex-row align-center" :colspan="numCols"
              :style="` min-height: 200px;`">
            <slot name="loading">
              <v-progress-linear v-if="haveVuetify" indeterminate :color="loaderColor"/>
              <template v-else>
                Loading...
              </template>
            </slot>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr
            v-for="(row, j) in showingServerData"
            class=""
            style=""
            @click="rowAction($event,row)"
            :key="(row.id != null ? row[keyName]  : j)"
            @row-action="rowAction($event,row)"
        >
          <td
              v-for="cell in headersForRows"
              class=""
              :key="cell.dataField"
              :colspan="cell.cols"
              :rowspan="cell.rows"
              :class="[cell.class, painCell(row, cell)]"
              :style="`${typeof cell.styles === 'function' ? cell.styles(row) : cell.styles};`"
          >
            <slot v-if="useSlots" :name="cell.dataField" :row="row" :id="row.id != null ? row.id : Math.random()"
                  :cell="cell">
              <span v-html="cellData(row, cell)"></span>
            </slot>
            <span v-else v-html="cellData(row, cell)"></span>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
    <div v-if="haveVuetify && havePagination" class="mt-3 d-flex flex-row justify-center align-center">
      <v-autocomplete
          class="pagination-table"
          v-model="rowsPerPage"
          :items="pagesPaginate"
          :disabled="disablePaginationSelector"
          solo
          dense
          filled
          height="25"
          rounded
          style="max-width: 150px"
      ></v-autocomplete>
      <v-pagination
          v-model="page"
          :length="pagesVuetify"
          :total-visible="7"
      >
      </v-pagination>

    </div>
  </div>
</template>

<script>


import GridTableHeaderRow from "./GridTableHeaderRow";
import GridTableEmptyData from "./GridTableEmptyData";
import GridTableBodyRow from "./GridTableBodyRow";

export default {
  name: "GridTableFull",
  components: {
    GridTableBodyRow, GridTableEmptyData, GridTableHeaderRow,
  },
  props: {
    headers: {
      type: [Array],
      required: true,
      default: () => []
    },
    typeTable: {
      type: String,
      default: 'grid',
      validator: (v) => {
        return ['grid', 'table'].includes(v)
      }
    },
    keyName:{
      type: String,
      default: 'id',
    },
    numCols: {
      type: [Number, String],
      required: true,
      default: 0
    },
    headersForRows: {
      type: [Array],
      required: true,
      default: () => []
    },
    maxTableHeight: {
      type: [String, Number],
      default: (window.screen.availHeight - 470 + 'px') ?? '550px',
    },
    loadingData: {
      type: Boolean,
      default: false
    },
    useSlots: {
      type: Boolean,
      default: true
    },
    dataRows: {
      type: Array,
      default: () => []
    },
    paintBackground: {
      type: [Function],
      default: () => {
      }
    },
    importsComponents: {
      type: [Array, Object]
    },
    loaderColor: {
      type: String,
      default: '#00599b'
    },
    labelSearch: {
      type: String,
      default: 'Поиск'
    },
    nameTable: {
      type: String,
      default: 'Название таблицы'
    },
    useVirtualScroller: {
      type: Boolean,
      default: false
    },
    useSearch: {
      type: Boolean,
      default: true
    },
    userPerPagesDefault: {
      type: Number,
      default: 25
    },
    disablePaginationSelector: {
      type: Boolean,
      default: false
    },
    havePagination: {
      type: Boolean,
      default: true
    },
    specificCalculateRowsPerPage: {
      type: Function,
      default: (row, index, perPage) => {
        return index < perPage;
      }
    },
    useOnlyPerPagesDefault: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: 'Ошибка получения данных'
    },
    sizeElementForVirtualScroller: {
      type: [String, Number],
      default: 40
    },
    dataFieldsForSearch: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    sizeElementForVirtualScrollerValidated() {
      return Number.parseInt(this.sizeElementForVirtualScroller)
    },
    maxTableHeightForScroller() {
      return window.screen.availHeight - 475 + 'px'
    },
    rowFields() {
      return this.headersForRows.map(el => ('item.' + el.dataField))
    },
    isGridTable() {
      return this.typeTable === 'grid'
    },
    haveVuetify() {
      return !!this.$vuetify
    },
    lengthData() {
      return this.dataRows.length
    },
    showingServerData() {
      // const start = (this.page - 1) * this.rowsPerPage
      // const end = (this.page) * this.rowsPerPage
      const start = this.specificPages[this.page - 1]?.start ?? 0;
      const end = this.specificPages[this.page - 1]?.end ?? 0;
      return this.havePagination ? this.filterData.slice(start, end) : this.filterData
    },
    pagesVuetify() {
      return this.specificPages.length ?? Math.ceil(this.filterData.length / this.rowsPerPage);
    },
    // specificPages() {
    //   const res = [];
    //   let prevStart = 0;
    //   let lastStart = 0;
    //   for (let index = 0; index < this.lengthData; index++) {
    //     const row = this.filterData[index]
    //     const specRes = this.specificCalculateRowsPerPage(row, index - lastStart, this.rowsPerPage)
    //     if (!specRes)
    //     {
    //       res.push({'start': prevStart, 'end': index})
    //       prevStart = index;
    //       lastStart = index;
    //     }
    //   }
    //   return res;
    // }
  },
  data() {
    return {
      localHeaders: this.headers,
      loading: false,
      searching: false,
      search: null,
      reseizing: false,
      resizedMaxHeight: null,
      sort: {header: null, type: 'asc'},
      /*pagination*/
      page: 1,
      filterData: [],
      specificPages: [],
      processingSpecificPages: false,
      rowsPerPage: this.userPerPagesDefault,
      pagesPaginate: [
        {value: 10, text: 10},
        {value: 25, text: 25},
        {value: 50, text: 50},
        {value: 75, text: 75},
        {value: 100, text: 100},
      ]
    }
  },
  watch: {
    async headers(newValue) {
      // await this.$refs['grid-table'].scrollIntoView({block: "start", inline: "nearest"});
      this.loading = true;
      this.localHeaders = newValue;
      // this.initClass();
      // await this.setTopPositions();
      this.loading = false;
    },
    rowsPerPage() {
      this.calcPagination()
    },
    lengthData(newValue) {
      if (this.useOnlyPerPagesDefault)
        return
      if (newValue <= 25)
        this.rowsPerPage = 25;
      else if (newValue <= 10)
        this.rowsPerPage = 10;
      else if (newValue <= 50)
        this.rowsPerPage = 50;
      else if (newValue <= 75)
        this.rowsPerPage = 75;
      else
        this.rowsPerPage = 100;
    },
    dataRows: {
      deep: true,
      handler(v) {
        this.calcFilterData(v);
      }
    },
    search(newValue) {
      if (!newValue) {
        this.filterData = this.dataRows;
        this.calcPagination();
        return;
      }
      this.searching = true;
      this.calcFilterData()
      setTimeout(() => {
        this.searching = false;
      }, 300)
    }
  },
  created() {
    this.calcFilterData(this.dataRows)
  },
  mounted() {
    // this.setTopPositions();
    // this.initClass();
    this.resizeMaxHeight();
    window.addEventListener('resize', this.resizeMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeMaxHeight)
    // window.removeEventListener('resize', this.setTopPositions)
  },
  methods: {
    changeSearch(newValue) {
      this.search = newValue
    },
    calcFilterData(data = this.dataRows) {
      this.filterData = !this.search ? data : data.filter(el => {
            if (!this.search)
              return true;
            let include = false
            this.dataFieldsForSearch.forEach(el2 => {
              if (include)
                return;
              include = String(el[el2]).toLowerCase().search(this.search.toLowerCase()) !== -1;
            })
            return include
          }
      )
      this.calcPagination()
    },
    async calcPagination() {
      if (this.processingSpecificPages)
        return
      this.processingSpecificPages = true;
      const res = [];
      let prevStart = 0;
      let lastStart = 0;
      for (let index = 0; index < this.lengthData; index++) {
        const row = this.filterData[index]
        const specRes = this.specificCalculateRowsPerPage(row, index - lastStart, this.rowsPerPage)
        if (!specRes)
        {
          res.push({'start': prevStart, 'end': index})
          prevStart = index;
          lastStart = index;
        }
        if (index === this.lengthData - 1){
          res.push({'start': prevStart, 'end': index + 1})
          prevStart = index;
          lastStart = index;
        }
      }
      this.specificPages = res;
      this.processingSpecificPages = false;
    },
    resizeMaxHeight() {
      if (this.reseizing) return;
      this.reseizing = true;
      const height = (window.screen.availHeight - 350) + 'px';
      if (this.maxTableHeight > height)
        this.resizedMaxHeight = height;
      else
        this.resizedMaxHeight = this.maxTableHeight;
      this.reseizing = false;
    },
    rowAction(e, row) {
      this.$emit('row-action', {e: e, row: row})
    },
    cellData(row, cell) {
      return cell.template ? cell.template(row) : row[cell.dataField]
    },
    calcStylesForRowCell(row, cell) {
      return `${typeof cell.styles === 'function' ? cell.styles(row) : cell.styles};` + `grid-column: span ${cell.colsDataCalc ? cell.colsDataCalc(row, cell) : cell.cols}; grid-row: span ${cell.rowsDataCalc ? cell.rowsDataCalc(row) : cell.rowsData};`
    },
    // eslint-disable-next-line no-unused-vars
    painCell(row, cell) {
      if (this.paintBackground(row))
        return this.paintBackground(row)();
    },
  },
}
</script>

<style lang="sass">
.pagination-table
  max-width: 70px !important

  .v-input__slot
    input
      min-width: 30px !important
      max-width: 40px !important
    padding: 0 5px 0 10px !important

  .v-text-field__details
    display: none

</style>
