<template>
  <div>
    <div style="display: flex; justify-content: space-between;">
      <!--      v-if="!blocked"-->
      <v-btn class="blue__v-btn" style="margin: 0 0 10px 0" @click="downloadExcel">
        Выгрузка Excel
      </v-btn>
      <v-btn
          v-if="!blocked"
          :disabled="blocked"
          class="blue__v-btn"
          style="margin: 0 0 10px auto"
          @click="sendToMon"
      >
        {{ statusBtnBlockText }}
      </v-btn>
    </div>
    <div style="display: flex; justify-content: space-between;">

    </div>
    <wrapper-preview-category customClass="section-preview_lk">
      <grid-table-full
          :blocked="blocked"
          :data-rows="showingServerData"
          :headers="tableHeaders.massive"
          :headers-for-rows="tableHeaders.massive"
          :loading-data="tableData.loading"
          use-only-per-pages-default
          :user-per-pages-default="50"
          :disable-pagination-selector="false"
          name-table=""
          :error="errorMainRequest"
          key-name="subId"
          :specific-calculate-rows-per-page="propsMethodForTable()"
          :data-fields-for-search="['sprFoName', 'sprOrgName', 'sprSpecName', 'sprSpecCode']"
          :num-cols="tableHeaders.countCells"
      >
        <template #nameTable>
          <v-autocomplete
              v-model="filterSpec"
              :items="specSelectorsDataArray"
              label="Направление подготовки"
              clearable
          ></v-autocomplete>
        </template>
        <template #actions="{ row }">
          <div>
            <div class="d-flex align-center justify-center flex-row mdi-btns">
              <v-btn :disabled="blocked" icon @click="editDialogOpen(row)">
                <v-icon color="primary" size="24">mdi-pencil</v-icon>
              </v-btn>
              <v-btn :disabled="blocked" icon @click="deleteOuterTableRow(row.id)">
                <v-icon color="primary" size="24">mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template #sprSpecName="{ row }">
          <div>{{ `${row.sprSpecCode} - ${row.sprSpecName} (${row.sprFoName})` }}</div>
        </template>
        <template #header-actions>
          <v-btn icon @click="addRowDialog" :disabled="blocked">
            <v-icon color="primary" size="33">mdi-plus</v-icon>
          </v-btn>
        </template>
        <template #subject="{ row }">
          <v-btn icon @click="openListSubjectDialog(row)">
            <v-icon color="primary" size="21">mdi-information</v-icon>
          </v-btn>
        </template>
        <template #innerActions="{ row }">
          <!--          <div v-for="(item, j) in row.array" :key="j">-->
          <div class="d-flex align-center justify-center flex-row">
            <v-btn :disabled="blocked || !row.item" icon @click="editInnerDialogOpen(row.id, row.array, row.item)">
              <v-icon color="primary" size="21">mdi-pencil</v-icon>
            </v-btn>
            <v-btn :disabled="blocked || !row.item" icon @click="deleteInnerTableRow(row.item.id, true, row)">
              <v-icon color="primary" size="21">mdi-delete</v-icon>
            </v-btn>
            <!--            <v-btn :disabled="blocked" icon-->
            <!--                   @click="addRowInnerDialog(row.id, row.array, row.sprOrgId, row.sprSpecId, row.sprFoId)">-->
            <!--              <v-icon color="primary" size="21">mdi-plus</v-icon>-->
            <!--            </v-btn>&lt;!&ndash;v-if="j === row.array.length - 1"&ndash;&gt;-->
            <v-btn
                v-if="showAddBtn(row)"
                :disabled="blocked"
                icon
                @click="addRowInnerDialog(row.id, row.array, row.sprOrgId, row.sprSpecId, row.sprFoId)"
            >
              <v-icon color="primary" size="21">mdi-plus</v-icon>
            </v-btn><!--v-if="row.array.length === 0"-->
          </div>
          <!--          </div>-->
        </template>
      </grid-table-full>
      <!--      <div class="mt-3 d-flex flex-row justify-center align-center">-->
      <!--        <v-autocomplete-->
      <!--            class="pagination-table"-->
      <!--            v-model="userPages"-->
      <!--            :items="pagesPaginate"-->
      <!--            solo-->
      <!--            dense-->
      <!--            filled-->
      <!--            height="25"-->
      <!--            rounded-->
      <!--            style="max-width: 150px"-->
      <!--            @change="changePage($event)"-->
      <!--        ></v-autocomplete>-->
      <!--        <v-pagination-->
      <!--            v-model="page"-->
      <!--            :length="pagesVuetify"-->
      <!--            :total-visible="7"-->
      <!--            @input="changePage($event)"-->
      <!--        >-->
      <!--        </v-pagination>-->
      <!--      </div>-->
      <v-dialog
          v-model="dialog"
          width="1300"
      >
        <v-card>
          <v-card-title v-if="!editDialog">
            Добавление организации
          </v-card-title>
          <v-card-title v-if="editDialog">
            Изменение организации
          </v-card-title>
          <v-autocomplete
              v-model="outerTableNewRow.org"
              :items="orgSelectorsArray"
              label="Организация"
              :loading="loadingEditDialog"
              @change="getSelectorSpec"
          ></v-autocomplete>
          <v-autocomplete
              v-model="outerTableNewRow.spec"
              :items="specSelectorsArray"
              :loading="loadingEditDialog"
              label="Направление подготовки"
              @change="getSelectorFo"
          ></v-autocomplete>
          <v-autocomplete
              v-model="outerTableNewRow.fo"
              :items="foSelectorsArray"
              :loading="loadingEditDialog"
              label="Форма обучения"
              @change="getCOValue(outerTableNewRow.org, outerTableNewRow.spec, outerTableNewRow.fo)"
          ></v-autocomplete>
          <v-text-field
              v-model="outerTableNewRow.numPlaces"
              disabled
              :loading="loadingEditDialog"
              label="Квота приёма"
              type="number"
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!editDialog" class="blue__v-btn" @click="addOuterTableRow">Добавить</v-btn>
            <v-btn v-if="editDialog" class="blue__v-btn" @click="editOuterTableRow">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="dialogInner"
          width="1300"
      >
        <v-card>
          <v-card-title>
            {{ !editDialogInner ? 'Добавление заявки респондента' : 'Изменение заявки респондента' }}
          </v-card-title>
          <v-text-field
              v-model="innerTableNewRow.val"
              label="Объём заявки респондента"
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="innerTableNewRow.zakazchik"
              label="Заказчик"
              type="text"
          ></v-text-field>
          <v-text-field
              v-model="innerTableNewRow.zakazchikInn"
              label='ИНН (не обязательно)'
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="innerTableNewRow.zakazchikKpp"
              label='КПП (не обязательно)'
              type="number"
          ></v-text-field>
          <v-text-field
              v-model="innerTableNewRow.orgTrud"
              label="Организация трудоустройства"
              type="text"
          ></v-text-field>
          <div class="d-flex flex-row align-center">
            <v-autocomplete
                v-model="innerTableNewRow.regionId"
                :items="regionsArray"
                label="Субъект трудоустройства"
                :rules="[
                    v=> !!v || 'Обязательное поле',
                    v => v && v.length > 0 || 'Выберите хотя бы 1 субъект'
                ]"
                multiple
            ></v-autocomplete>

            <v-btn class=" white__v-btn" @click="choseAllSubjects($event,true)">
              Очистить все
            </v-btn>
            <v-btn class="mx-1 blue__v-btn" @click="choseAllSubjects">
              Выбрать все
            </v-btn>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!editDialogInner" class="blue__v-btn" @click="addInnerTableRow">Добавить</v-btn>
            <v-btn v-if="editDialogInner" class="blue__v-btn" @click="editInnerTableRow">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="tooMany"
          width="640"
      >
        <v-card>
          <v-card-title>
            Объём заявки превышает объём оставшейся квоты приёма
          </v-card-title>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="loading"
          width="335"
          persistent
      >
        <v-card>
          <v-card-title>
            Загрузка данных, подождите
          </v-card-title>
        </v-card>
      </v-dialog>
      <v-dialog max-width="600" v-model="showListRegionsDialog">
        <v-card>
          <v-card-title>{{ titleListRegionsDialog }}</v-card-title>
          <v-card-text>
            <div v-for="item in dataListRegionsDialog" :key="item.regionId">{{ item.regionName }}</div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </wrapper-preview-category>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import request from "@/services/request";
import WrapperPreviewCategory from "@/components/GridTable/dataFolder/WrapperPreviewCategory";
import QuotaTableHeaders from "@/components/GridTable/dataFolder/QuotaTableHeaders";
import axios from "axios";
import GridTableFull from "@/components/GridTableFromKcp/GridTableFull";

export default {
  name: "QuotaTable",
  components: {GridTableFull, WrapperPreviewCategory},
  data: () => ({
    /*listRegionsForDialog*/
    showListRegionsDialog: false,
    dataListRegionsDialog: [],
    titleListRegionsDialog: "",

    tableHeaders: [],
    filterSpec: null,
    tableData: {
      loading: false,
      change: {value: false, id: null},
      error: null,
      editRow: null,
      data: []
    },
    allDataByFounderId: [],
    outerTableNewRow: {
      id: null,
      org: null,
      spec: null,
      fo: null,
      numPlaces: null,
      kcpCoValue: null,
      row: null
    },
    innerTableNewRow: {
      parentId: null,
      id: null,
      val: 0,
      zakazchik: null,
      zakazchikInn: null,
      zakazchikKpp: null,
      orgTrud: null,
      regionId: null,
      availableRegions: [],
    },
    innerArray: null,
    outerTableArray: [],
    sprRegionData: [],
    dialog: false,
    errorMainRequest: false,
    loadingEditDialog: false,
    dialogInner: false,
    editDialog: false,
    editDialogInner: false,
    orgSelectorsArray: [],
    specSelectorsArray: [],
    foSelectorsArray: [],
    regionSelectorArray: [],
    specSelectorsDataArray: [],
    blocked: false,
    loading: false,
    filter: {
      sprUsl: [],
      sprFo: [],
      sprFf: []
    },
    status: null,
    tooMany: false,
    //paginator
    page: 1,
    userPages: 50,
    pagesPaginate: [
      {value: 25, text: 25},
      {value: 50, text: 50},
      {value: 100, text: 100},
    ]
  }),
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles',
      isRegion: 'isRegion'
    }),
    statusBtnBlockText(){
      switch (this.status) {
        case 'SENT':
          return 'Отправлено в МОН'
        case 'BLOCKED':
          return 'Заблокировано'
        case 'ACCEPTED_MON':
          return 'Согласовано МОН'
        case 'ACCEPTED_GOV':
          return 'Согласовано Правительством'
        default:
          return 'Отправить в МОН'
      }
    },
    regionsArray() {
      if (this.innerTableNewRow.availableRegions.length !== 0) {
        return this.innerTableNewRow.availableRegions
      } else {
        return this.regionSelectorArray
      }
    },
    showingServerData() {
      return this.filteredData/*.slice((this.page - 1) * this.userPages, (this.page) * this.userPages)*/
    },
    // pagesVuetify() {
    //   return Math.ceil(this.filteredData.length / this.userPages);
    // },
    filteredData() {
      return this.allDataByFounderId.filter(el => !this.filterSpec || el.sprSpecId === this.filterSpec)
    }
  },

  created() {
    this.getAllByFounderId();
    this.getAllSprRegion();
    this.getSelectorOrgs();
    this.tableHeaders = QuotaTableHeaders(this);
  },
  mounted() {
    this.getFounderStatus()
  },
  methods: {
    propsMethodForTable() {
      return (row, index, perPage) => {
        if (index < perPage) return true
        return index >= perPage && !row?.mainRow;
      }
    },
    choseAllSubjects(e, clear = false) {
      if (clear)
        this.innerTableNewRow.regionId = []
      else
        this.innerTableNewRow.regionId = this.regionsArray.map(el => el.value)
    },
    changePage() {
      this.tableData.loading = true;
      setTimeout(() => {
        this.tableData.loading = false;
      }, 300)
    },
    openListSubjectDialog(row) {
      this.titleListRegionsDialog = `Субъекты трудоустройства`;//${row.item.zakazchik} - ${row.item.val}
      this.dataListRegionsDialog = row.item.sprRegions;
      this.showListRegionsDialog = true;
    },
    showAddBtn(row) {
      // console.log(row.array[row.array.length - 1]?.id , row.item.id)
      return !row.item || (row.array[row.array.length - 1]?.id === row.item.id)
    },
    regionsNamesList(item) {
      let str = '';
      for (var r of item) {
        if (str !== '') {
          str += "; " + r.regionName;
        } else {
          str += r.regionName;
        }
      }
      return str;
    },

    requestAmount(row, item) {
      if (!item)
        return
      if (row.founderStatus === 'ACCEPTED_MON' || row.founderStatusRegion === 'ACCEPTED_MON') {
        return item.valStage3
      } else if (row.founderStatus === 'ACCEPTED_GOV' || row.founderStatusRegion === 'ACCEPTED_GOV') {
        return item.valStage4
      } else {
        return item.val
      }
    },

    async getFounderStatus() {
      try {
        let uri = `/api/spr/founder/founderId?founderId=${this.getUser.founderID}`
        if (this.isRegion) uri = `/api/spr/region/regionId?regionId=${this.getUser.regionID}`
        const res = await request({
          endpoint: uri,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data.founderStatus?.founderWorkflow === 'SENT') {
          this.blocked = true
          console.log('getFounderStatus успех');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getAllByFounderId() {
      this.loading = true;
      try {
        let uri = `/api/moduledatagather/getAllByFounderId?founderId=${this.getUser.founderID}`
        if (this.isRegion) uri = `/api/moduledatagather/getAllByFounderId?regionId=${this.getUser.regionID}`
        if (this.filterSpec !== null && !isNaN(this.filterSpec)) uri += `&specId=${this.filterSpec}`
        const res = await request({
          endpoint: uri,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          this.loading = false;
          console.log('getAllByFounderId успех');
          const rows = new Set();
          this.allDataByFounderId = res.data.reduce((acc, el) => {
            rows.add(el.sprSpecId);
            this.specSelectorsDataArray.push({
              text: `(${el.sprSpecCode}) ${el.sprSpecName}`,
              value: el.sprSpecId,
            })
            acc.push(
                {
                  ...el,
                  subId: `${el.id}.${el.array[0]?.id}`,
                  requestAmount: this.requestAmount(el, el.array[0]),
                  item: el.array[0],
                  mainRow: true
                },
                ...el.array.reduce((acc, item, i) => {
                      if (i !== 0)
                        acc.push({
                          ...el,
                          subId: `${el.id}.${item.id}`,
                          mainRow: false,
                          item: item,
                          requestAmount: this.requestAmount(el, item),

                        })
                      return acc
                    }
                    , [])
            );
            // }
            return acc
            // ...el,
            // requestAmount: el.array.map(el2 => this.requestAmount(el, el2)),
            // subject: el.array.map(el2 => this.regionsNamesList(el2.sprRegions)),
          }, []);
          // this.blocked = true

          if (this.allDataByFounderId[0]) {
            if ((this.allDataByFounderId[0].founderStatus && this.allDataByFounderId[0].founderStatus !== 'WORKS') ||
                (this.allDataByFounderId[0].founderStatusRegion && this.allDataByFounderId[0].founderStatusRegion !== 'WORKS')) {
              this.blocked = true
            }
            if (this.allDataByFounderId[0].founderStatus === 'ACCEPTED_GOV' || this.allDataByFounderId[0].founderStatusRegion === 'ACCEPTED_GOV') {
              this.status = 'ACCEPTED_GOV';
            } else if (this.allDataByFounderId[0].founderStatus === 'ACCEPTED_MON' || this.allDataByFounderId[0].founderStatusRegion === 'ACCEPTED_MON') {
              this.status = 'ACCEPTED_MON';
            } else if (this.allDataByFounderId[0].founderStatus === 'SENT' || this.allDataByFounderId[0].founderStatusRegion === 'SENT') {
              this.status = 'SENT';
            } else if (this.allDataByFounderId[0].founderStatus === 'BLOCKED' || this.allDataByFounderId[0].founderStatusRegion === 'BLOCKED') {
              this.status = 'BLOCKED';
            }
          }
          this.errorMainRequest = false
        }
      } catch (e) {
        this.loading = false;
        this.errorMainRequest = true;
        console.error('1',e);
      }
      this.loading = false;
    },
    async getSelectorOrgs() {
      try {
        const res = await request({
          endpoint: `/api/kcp_quota/orgs`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.orgSelectorsArray.push({
              text: res.data[i].nameFull,
              value: res.data[i].id,
            });
          }
          console.log('getSelectorOrgs успех');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getSelectorSpec() {
      this.specSelectorsArray = [];
      this.outerTableNewRow.spec = '';
      this.outerTableNewRow.fo = '';
      this.outerTableNewRow.numPlaces = '';
      this.outerTableNewRow.kcpCoValue = '';
      try {
        const res = await request({
          endpoint: `/api/kcp_quota?orgId=${this.outerTableNewRow.org}`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.specSelectorsArray.push({
              text: `(${res.data[i].specCode}) ${res.data[i].specName}`,
              value: res.data[i].specId,
            });
          }
          console.log('getSelectorSpec успех');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getSelectorFo() {
      this.foSelectorsArray = [];
      this.outerTableNewRow.fo = '';
      this.outerTableNewRow.numPlaces = '';
      this.outerTableNewRow.kcpCoValue = '';
      try {
        const res = await request({
          endpoint: `/api/kcp_quota?orgId=${this.outerTableNewRow.org}&specId=${this.outerTableNewRow.spec}`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.foSelectorsArray.push({
              text: res.data[i].foName,
              value: res.data[i].foId,
            });
          }
          console.log('getSelectorFo успех');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getCOValue(org, spec, fo) {
      try {
        const res = await request({
          endpoint: `/api/kcp_quota?orgId=${org}&specId=${spec}&foId=${fo}`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        this.innerTableNewRow.availableRegions = [];
        if (res.status === 200 && res.data) {
          this.outerTableNewRow.numPlaces = res.data[0].value;
          this.outerTableNewRow.kcpCoValue = res.data[0].kcpCoValue;
          for (let i = 0; i < res.data[0].regions.length; i++) {
            if (res.data[0].regions[i].regionName && res.data[0].regions[i].regionId) {
              this.innerTableNewRow.availableRegions.push({
                text: res.data[0].regions[i].regionName,
                value: res.data[0].regions[i].regionId
              })
            }
          }
          this.innerTableNewRow.availableRegions = this.innerTableNewRow.availableRegions.sort((a, b) => a.value - b.value)
          console.log('getCOValue успех');
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getAllSprRegion() {
      try {
        const res = await request({
          endpoint: `/api/spr/region/getAll`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].regionName && res.data[i].regionId) {
              this.regionSelectorArray.push({
                text: res.data[i].regionName,
                value: res.data[i].regionId
              })
            }
          }
          console.log('getAllSprRegion успех');
          this.sprRegionData = res.data;
        }
      } catch (e) {
        console.error(e);
      }
    },

    /////////////////////////////////////// add
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    async sendToMon() {
      try {
        let uri = `/api/moduledatagather/sent?founderId=${this.getUser.founderID}`
        if (this.isRegion) uri = `/api/moduledatagather/sentRegion?regionId=${this.getUser.regionID}`
        const res = await request({
          endpoint: uri,
          method: 'get',
          token: `${this.getAuthData.token}`,
        });
        if (res.status === 200 && res.data.founderStatus.founderWorkflow === 'SENT') {
          this.blocked = true
          console.log('sendToMon успех');
        }else{
          const msg = res?.error?.response?.data?.errorMessage ?? 'Ошибка блокировки';
          console.error('bbb', msg);
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: msg,
            timeout: 10000
          })
        }
        this.dialog = false;
      } catch (e) {
        console.error('aaa',e);
      }
    },
    addRowDialog() {
      this.editDialog = false;
      this.dialog = true;
      this.outerTableNewRow.org = null;
      this.outerTableNewRow.row = null;
      this.outerTableNewRow.spec = null;
      this.outerTableNewRow.fo = null;
      this.outerTableNewRow.numPlaces = null;
    },
    addRowInnerDialog(parentId, innerArray, orgId, specId, foId) {
      this.getCOValue(orgId, specId, foId);
      this.editDialogInner = false;
      this.dialogInner = true;
      this.innerTableNewRow.parentId = parentId;
      this.innerArray = innerArray;
      this.innerTableNewRow.id = null;
      this.innerTableNewRow.val = null;
      this.innerTableNewRow.zakazchik = null;
      this.innerTableNewRow.zakazchikInn = null;
      this.innerTableNewRow.zakazchikKpp = null;
      this.innerTableNewRow.orgTrud = null;
      this.innerTableNewRow.regionId = null;
    },
    async addOuterTableRow() {
      try {
        const res = await request({
          endpoint: `/api/moduledatagather/set`,
          method: 'post',
          token: `${this.getAuthData.token}`,
          body: {
            "sprOrgFounderId": this.getUser.founderID, // id аккаунта
            "sprRegionId": this.getUser.regionID,
            "sprOrgId": this.outerTableNewRow.org, // 1
            "sprSpecId": this.outerTableNewRow.spec, // 2
            "sprFoId": this.outerTableNewRow.fo, // 3
            "numPlaces": this.outerTableNewRow.numPlaces, // квота
            "kcpCoValue": this.outerTableNewRow.kcpCoValue, // квота
            "array": []
          }
        });
        if (res.status === 200 && res.data) {
          // await this.getAllByFounderId();
          const addData = {...res.data, mainRow: true, subId: res.data.id, item: null}
          this.allDataByFounderId.push(addData)
          console.log('addOuterTableRow успех');
          this.dialog = false;
        }
      } catch (e) {
        console.error(e);
      }
    },
    //Inner Table Methods
    async addInnerTableRow() {
      let row = this.allDataByFounderId.find(el => el.id === this.innerTableNewRow.parentId);
      let totalQuota = row.numPlaces;
      let sum = this.innerArray.reduce((acc, el) => {
        if (el.id !== this.innerTableNewRow.id)
          acc += el.val
        return acc
      }, 0);
      sum = sum + Number(this.innerTableNewRow.val);
      if (sum <= totalQuota) {
        try {
          const res = await request({
            endpoint: `/api/moduledatagather/setpart`,
            method: 'post',
            token: `${this.getAuthData.token}`,
            body: {
              "parentId": this.innerTableNewRow.parentId,
              "val": Number.parseInt(this.innerTableNewRow.val),
              "zakazchik": this.innerTableNewRow.zakazchik,
              "zakazchikInn": this.innerTableNewRow.zakazchikInn,
              "zakazchikKpp": this.innerTableNewRow.zakazchikKpp,
              "orgTrud": this.innerTableNewRow.orgTrud ?? '',
              "regionId": this.innerTableNewRow.regionId
            }
          });
          if (res.status === 200 && res.data) {
            // await this.getAllByFounderId();
            const subRow = res.data
            const mainRowIndex = this.allDataByFounderId.findIndex(el => el.id === this.innerTableNewRow.parentId);
            const mainRow = this.allDataByFounderId[mainRowIndex];
            const subId = `${this.innerTableNewRow.parentId}.${subRow.id}`
            const requestAmount = this.requestAmount(mainRow, subRow)
            if (!this.innerArray.length) {
              mainRow.item = subRow
              mainRow.requestAmount = requestAmount
              mainRow.subId = subId
              mainRow.array.push(subRow)
            } else {
              mainRow.array.push({...subRow, requestAmount})
              const indexToAdd = mainRowIndex + mainRow.array.length - 1
              const enterData = {...mainRow, mainRow: false, subId, requestAmount, item: subRow}
              this.allDataByFounderId.splice(indexToAdd, 0, enterData)
            }
            console.log('addInnerTableRow успех');
            this.dialogInner = false;
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        this.tooMany = true
      }
    },
    async editInnerTableRow() {
      let row = this.allDataByFounderId.find(el => el.id === this.innerTableNewRow.parentId);
      let totalQuota = row.numPlaces;
      let sum = this.innerArray.reduce((acc, el) => {
        if (el.id !== this.innerTableNewRow.id)
          acc += el.val
        return acc
      }, 0); // вычитание изменяемой подстроки из суммы, чтобы прибавить новое значение
      sum = sum + Number(this.innerTableNewRow.val);
      if (sum <= totalQuota) {
        try {
          const res = await request({
            endpoint: `/api/moduledatagather/setpart`,
            method: 'post',
            token: `${this.getAuthData.token}`,
            body: {
              "parentId": this.innerTableNewRow.parentId,
              "id": this.innerTableNewRow.id,
              "val": Number.parseInt(this.innerTableNewRow.val),
              "zakazchikInn": this.innerTableNewRow.zakazchikInn,
              "zakazchikKpp": this.innerTableNewRow.zakazchikKpp,
              "zakazchik": this.innerTableNewRow.zakazchik,
              "orgTrud": this.innerTableNewRow.orgTrud,
              "regionId": this.innerTableNewRow.regionId
            }
          });
          if (res.status === 200 && res.data) {
            const mainRowIndex = this.allDataByFounderId.findIndex(el => el.id === this.innerTableNewRow.parentId);
            const mainRow = this.allDataByFounderId[mainRowIndex];
            const subId = `${this.innerTableNewRow.parentId}.${this.innerTableNewRow.id}`
            const newArr = mainRow.array.map(el => {
              if (el.id === this.innerTableNewRow.id) {
                const mapRes = {...el, ...res.data,}
                return {...mapRes, requestAmount: this.requestAmount(mainRow, mapRes)}
              } else return el
            })
            const requestAmount = this.requestAmount(mainRow, res.data)
            const mapRes = {...res.data, requestAmount: requestAmount}
            if (mainRow.subId === subId) {
              mainRow.item = mapRes;
              mainRow.requestAmount = requestAmount;
            } else {
              for (let i = mainRowIndex; i < (mainRowIndex + mainRow.array.length); i++) {
                if (this.allDataByFounderId[i].subId === subId) {
                  this.allDataByFounderId[i].item = mapRes
                  this.allDataByFounderId[i].requestAmount = requestAmount;
                }
              }
            }
            mainRow.array = newArr            // await this.getAllByFounderId();
            console.log('editInnerTableRow успех');
            this.dialogInner = false;
            this.innerTableNewRow.parentId = null;
            this.innerTableNewRow.id = null;
            this.innerTableNewRow.val = null;
            this.innerTableNewRow.zakazchik = null;
            this.innerTableNewRow.orgTrud = null;
            this.innerTableNewRow.regionId = null;
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        console.error('edit ', sum, totalQuota)
        this.tooMany = true
      }
    },
    async deleteInnerTableRow(id, removeRowFromMainArray = false, mainRow) {
      try {
        // return;
        // eslint-disable-next-line
        const res = await request({
          endpoint: `/api/moduledatagather/deletepart?collectionId=${id}`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          // await this.getAllByFounderId();
          if (removeRowFromMainArray) {
            const indexToRowRemove = this.allDataByFounderId.findIndex(el => el.subId === mainRow.subId)
            const indexMainRow = this.allDataByFounderId.findIndex(el => el.id === mainRow.id)
            const mainItem = this.allDataByFounderId[indexMainRow]
            const newArr = mainItem.array.filter(el => el.id !== id)
            mainItem.array = newArr
            for (let i = indexMainRow; i < (newArr.length + 1); i++)
              this.allDataByFounderId[i].array = newArr;
            if (mainRow.subId === mainItem.subId) {
              if (mainItem.array.length === 0)
                this.allDataByFounderId[indexToRowRemove].item = null;
              else {
                const indSlice = indexToRowRemove + 1;
                if (!this.allDataByFounderId[indSlice])
                  return;
                ['item', 'subId', 'requestAmount'].forEach(
                    el => this.allDataByFounderId[indexToRowRemove][el] = this.allDataByFounderId[indSlice][el]
                )
                this.allDataByFounderId.splice(indSlice, 1)
              }
            } else
              this.allDataByFounderId.splice(indexToRowRemove, 1)
          }
          console.log('deleteInnerTableRow успех');
        }
      } catch (e) {
        console.error(e);
      }
    },
    /////////////////////////////////////// edit

    async editDialogOpen(row) {
      this.dialog = true;
      this.editDialog = true;
      this.loadingEditDialog = true;
      this.outerTableNewRow.row = row;
      this.outerTableNewRow.id = row.id;
      this.outerTableNewRow.org = row.sprOrgId;
      await this.getSelectorSpec();
      this.outerTableNewRow.spec = row.sprSpecId;
      await this.getSelectorFo();
      this.outerTableNewRow.fo = row.sprFoId;
      this.outerTableNewRow.numPlaces = row.numPlaces;
      this.outerTableNewRow.kcpCoValue = row.kcpCoValue;
      this.loadingEditDialog = false;
    },
    editInnerDialogOpen(parentId, innerArray, item) {
      this.dialogInner = true;
      this.editDialogInner = true;
      this.innerTableNewRow.parentId = parentId;
      this.innerArray = innerArray;
      this.innerTableNewRow.id = item.id;
      this.innerTableNewRow.val = item.val;
      this.innerTableNewRow.zakazchikInn = item.zakazchikInn;
      this.innerTableNewRow.zakazchikKpp = item.zakazchikKpp;
      this.innerTableNewRow.zakazchik = item.zakazchik;
      this.innerTableNewRow.orgTrud = item.orgTrud;
      const pickedRegionsArray = [];
      for (let i = 0; i < item.sprRegions.length; i++) {
        pickedRegionsArray.push(item.sprRegions[i].regionId);
      }
      this.innerTableNewRow.regionId = pickedRegionsArray;
    },
    async editOuterTableRow() {
      try {
        const res = await request({
          endpoint: `/api/moduledatagather/set`,
          method: 'post',
          token: `${this.getAuthData.token}`,
          body: {
            "id": this.outerTableNewRow.id,
            "sprOrgFounderId": this.getUser.founderID, // id аккаунта
            "sprRegionId": this.getUser.regionID,
            "sprOrgId": this.outerTableNewRow.org,
            "sprSpecId": this.outerTableNewRow.spec,
            "sprFoId": this.outerTableNewRow.fo,
            "numPlaces": this.outerTableNewRow.numPlaces, // квота
            "kcpCoValue": this.outerTableNewRow.kcpCoValue, // квота
            "array": []
          }
        });
        if (res.status === 200 && res.data) {
          //this.outerTableNewRow?.row
          const mainRowIndex = this.allDataByFounderId.findIndex(el => el.id === this.outerTableNewRow.id);
          const mainRow = this.allDataByFounderId[mainRowIndex];
          const mainRowArray = mainRow.array;
          // const mainRowArrayDelete = mainRowArray.map(el => this.deleteInnerTableRow(el.id));
          // await Promise.allSettled(mainRowArrayDelete).then(() => {
          for (let i = mainRowIndex + 1; i < (mainRowArray.length - 1); i++) {
            Object.keys(this.allDataByFounderId[i]).forEach(key => this.allDataByFounderId[i][key] = res.data[key])
          }
          Object.keys(mainRow).forEach(key => mainRow[key] = res.data[key])
          const requestAmount = this.requestAmount(mainRow, mainRowArray[0])
          mainRow.mainRow = true
          mainRow.requestAmount = requestAmount
          mainRow.item = mainRowArray[0] ?? null
          mainRow.subId = mainRow.id
          // })
          // const arrayForDelete = this.allDataByFounderId.filter(el => el.id === this.outerTableNewRow.id)[0].array
          // const objForTest = this.allDataByFounderId.filter(el => el.id === this.outerTableNewRow.id)[0]
          // if (objForTest.numPlaces !== this.outerTableNewRow.numPlaces ||
          //     objForTest.sprFoId !== this.outerTableNewRow.fo ||
          //     objForTest.sprSpecId !== this.outerTableNewRow.spec ||
          //     objForTest.sprOrgId !== this.outerTableNewRow.org) {

          // for (let i = 0; i < arrayForDelete.length; i++) {
          //   await this.deleteInnerTableRow(arrayForDelete[i].id);
          // }
          // }
          // await this.getAllByFounderId();
          console.log('editOuterTableRow успех');
          this.dialog = false;
          this.outerTableNewRow.id = null;
          this.outerTableNewRow.org = null;
          this.outerTableNewRow.spec = null;
          this.outerTableNewRow.fo = null;
          this.outerTableNewRow.numPlaces = null;
          this.outerTableNewRow.kcpCoValue = null;
          this.outerTableNewRow.row = null;
        }
      } catch (e) {
        console.error(e);
      }
    },


    /////////////////////////////////////// delete

    async deleteOuterTableRow(id) {
      try {
        const res = await request({
          endpoint: `/api/moduledatagather/delete?collectionId=${id}`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          const indexRemove = this.allDataByFounderId.findIndex(el => el.id === id);
          const len = this.allDataByFounderId[indexRemove].array?.length ?? 1
          const lengthArray = len === 0 ? 1 : len
          this.allDataByFounderId.splice(indexRemove, lengthArray);
          // await this.getAllByFounderId();
          console.log('deleteOuterTableRow успех');
        }
      } catch (e) {
        console.error(e);
      }
    },


    // выгрузка Excel /////////////////////////////////////////////
    async downloadExcel() {
      let uri = `/api/moduledatagather/report/xlsx?founderId=${this.getUser.founderID}`;
      if (this.isRegion) uri = `/api/moduledatagather/report/xlsx?regionId=${this.getUser.regionID}`
      const responseAxios = await axios.get(uri, {
        responseType: 'blob',
        token: `${this.getAuthData.token}`
      })
      this.saveByteArray("ReportFunc.xlsx", responseAxios.data)
    },
    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/xlsx"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    },
  }
}
</script>

<style lang="sass">
.pagination-table
  max-width: 70px !important

  .v-input__slot
    input
      min-width: 30px !important
      max-width: 40px !important
    padding: 0 5px 0 10px !important

  .v-text-field__details
    display: none

.yellow-background
  background-color: #fff3cd
  color: #D4990A

.section-preview__header
  font-size: 24px
  font-weight: bold
  margin: 16px 0
  color: #334D6E

.dialog-btn
  .v-btn__content
    padding: 10px 5px

.v-autocomplete
  padding: 0 24px

.v-text-field
  padding: 0 24px

.mdi-btns
  align-self: start
</style>
