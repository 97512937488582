<template>
  <div
      class="table-grid-header-row"
      :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr )`"
      ref="table-grid-header-row"
  >
      <div
          v-for="(item, i) in headers" :key="i"
          class="table-grid-header__cell"
          :class="item.headerClass"
          :ref="'table-grid-header__cell-'+item.dataField"
          :style="`grid-column: span ${item.cols }; grid-row: span ${item.rows};
               top: ${topFixedPositionForHeaderCell(item).offsetTop}px; ${item.headerStyles}`"
      >
        <slot :name="'header-'+item.dataField" :item="item">
          <span v-html="item.text"></span>
          <v-tooltip
              bottom
              max-width="650px"
              v-if="haveVuetify && item.headerTooltip != null && item.headerTooltip"
              :color="color"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  :size="15"
                  class="btn__icon grey-light-color__important"
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
            <p class="white--text" v-html="item.headerTooltipText()"></p>
          </v-tooltip>
          <span
              class="d-flex align-center justify-center"
              v-if="item.sortable"
          >
                <template v-if="sort.header === item.dataField">

                </template>
                <template v-else>

                </template>
          </span>
        </slot>
      </div>
  </div>

</template>

<script>
export default {
  name: "GridTableHeaderRow",
  props: {
    numCols: {
      type: Number,
      default: 0
    },
    headers: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    color: {
      type: String,
      default: ''
    },
    haveVuetify: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localHeaders: this.headers,
      sort: {header: null, type: 'asc'}
    }
  },
  mounted() {
    this.setTopPositions();
  },
  methods: {
    async setTopPositions() {
      this.localHeaders = await Promise.all(
          this.localHeaders.map(async el => {
            const offsetHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetHeight : '';
            const offsetTop = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.offsetTop : '';
            const clientHeight = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientHeight : '';
            const clientWidth = this.$refs['table-grid-header__cell-' + el.dataField] ? this.$refs['table-grid-header__cell-' + el.dataField][0]?.clientWidth : '';
            return {
              ...el,
              offsetHeight: offsetHeight,
              offsetTop: offsetTop - 2,
              clientHeight: clientHeight,
              clientWidth: clientWidth
            }
          })

      )
    },
    clickSort(e, type = null, typeCell) {
      // console.log(e, ' - ', type);
      this.sort = {header: e.dataField, type: type ?? 'asc'};
      const directions = {
        asc: 1,
        desc: -1
      };
      const direction = directions[type] ?? 1;
      switch (typeCell) {
        case 'number':
          if (e.template)
            this.dataRows.sort((a, b) => direction * (e.template(a) - e.template(b)));
          else
            this.dataRows.sort((a, b) => direction * (a[e.dataField] - b[e.dataField]));
          break;
        case 'string': {
          if (e.template)
            this.dataRows.sort((a, b) => direction * e.template(a).localeCompare(e.template(b), ["ru", 'en'], {caseFirst: 'upper'}));
          else
            this.dataRows.sort((a, b) => direction * a[e.dataField].localeCompare(b[e.dataField], ["ru", 'en'], {caseFirst: 'upper'}));
          break;
        }
        default: {
          if (e.template)
            this.dataRows.sort((a, b) => direction * e.template(a).localeCompare(e.template(b), ["ru", 'en'], {caseFirst: 'upper'}));
          else
            this.dataRows.sort((a, b) => direction * a[e.dataField].localeCompare(b[e.dataField], ["ru", 'en'], {caseFirst: 'upper'}));
          break;
        }
      }
    },
    topFixedPositionForHeaderCell(cell) {
      return this.localHeaders.length > 0 ? this.localHeaders.find(el => el.dataField === cell.dataField) : ''
    },
  },
}
</script>

<style scoped>

</style>