<template>
  <div
      class="table-grid-body-row__cell"
      :key="cell.dataField"
      :class="[cell.class, painCell(row, cell)]"
      :style="calcStylesForRowCell(row,cell)"
  >
    <!--           grid-template-columns: minmax(200px, ${(numCols )}fr); -->
    <slot :name="cell.dataField" :value="row[cell.dataField]" :row="row" :id="row.id != null ? row.id : Math.random()" :cell="cell">
      <span style="width:100%;" v-html="cellData(row, cell)"></span>
    </slot>
  </div>
</template>

<script>
export default {
  name: "GridTableBodyCell",
  props: {
    row: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    paintBackground: {
      type: [Function],
      default: null
    },
    rowIndex: {
      type: Number,
    },
    cell: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    cellData(row, cell) {
      return cell.template ? cell.template(row) : row[cell.dataField]
    },
    calcStylesForRowCell(row, cell) {
      return `${typeof cell.styles === 'function' ? cell.styles(row, this.rowIndex) : cell.styles};` +
          `grid-column: span ${cell.colsDataCalc ? cell.colsDataCalc(row, cell, this.rowIndex) : cell.cols};
          grid-row: span ${cell.rowsDataCalc ? cell.rowsDataCalc(row, this.rowIndex) : cell.rows};`
    },
    painCell(row) {
      if (this.paintBackground && this.paintBackground(row))
      {
        return this.paintBackground(row)(row);
      }else return ''
    },
  },
}
</script>

<style scoped>

</style>
