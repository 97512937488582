<template>
  <div
      class="table-grid-body-row"
      :style="`grid-column: span ${numCols}; grid-template-columns : repeat(${numCols}, 1fr)`"
      @click="rowAction($event,row)"
  >
    <grid-table-body-cell
        v-for="cell in headers"
        :row="row"
        :cell="cell"
        :row-index="index"
        :paint-background="paintBackground"
        :key="cell.dataField"
    >
<!--      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />-->
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
        <slot :name="name" v-bind="slotData"/>
      </template>
    </grid-table-body-cell>
  </div>
</template>

<script>
import GridTableBodyCell from "./GridTableBodyCell";

export default {
  name: "GridTableBodyRow",
  components: {GridTableBodyCell},
  props: {
    numCols: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
    },
    paintBackground: {
      type: [Function],
      default: () => {
      }
    },
    row: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    headers: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
  },
  methods: {
    rowAction(e, row) {
      this.$emit('row-action', {e: e, row: row})
    },
    cellData(row, cell) {
      return cell.template ? cell.template(row) : row[cell.dataField]
    },
    calcStylesForRowCell(row, cell) {
      return `${typeof cell.styles === 'function' ? cell.styles(row) : cell.styles};` + `grid-column: span ${cell.colsDataCalc ? cell.colsDataCalc(row, cell) : cell.cols}; grid-row: span ${cell.rowsDataCalc ? cell.rowsDataCalc(row) : cell.rows};`
    },
    painCell(row) {
      if (this.paintBackground(row))
        return this.paintBackground(row)();
    },
  },
}
</script>

<style scoped>

</style>